@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
* {
    font-family: 'Montserrat', sans-serif;
}

h1,h2,h3,h4{
    font-family: 'Bebas Neue', sans-serif;
}

input:autofill{
    filter:none;
}


input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    /* -webkit-text-font-size: 20px !important; */
    -webkit-box-shadow: 0 0 0 1rem #1d1f2e inset !important;
    background-clip: content-box !important;
    font-family: "Roboto", sans-serif !important;
    -webkit-text-size-adjust: auto;
    font-size: 24px;
}

input:-webkit-autofill::first-line {
    -webkit-text-fill-color: white !important;
    -webkit-text-font-size: 20px !important;
    -webkit-box-shadow: 0 0 0 1rem #1d1f2e inset !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 24px;
    background-clip: content-box !important;
}


.footericon{
    padding: 0.6rem 1.8em ;
            margin-top: 2rem;
            margin-left: 1rem;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0%;

            border-radius: 50%;
            background-color: black;
            cursor: pointer;
}



@media screen and (max-width: 390px) {
    .css-1b402ef-MuiTypography-root.error-register{
        font-size: 7px;
    }
    
    .footericon.MuiBox-root.css-1najy0k {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    margin-left: 16px;

}


}
@media screen and (max-width: 375px) {
    .css-1b402ef-MuiTypography-root.error-register{
        font-size: 3px;
    }
    .footericon.css-13utstl {
        /* display: flex; */
        justify-content: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    padding: auto;
    font-size: x-small;

}
}

@media screen and (max-width: 426px) {
    .css-1b402ef-MuiTypography-root.error-register{
        font-size: 11px;
    }
    .footericon.css-13utstl {
        display: flex;
        justify-content: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    padding: auto;
    font-size: small;

}
.maindiv-homelog{
    display: flex;
    justify-content: center;
}
.footericon{
    margin-left: 0;
}
.footer-text{
    text-align: center;
}
}

@media screen and (min-width: 768px) {
    
    .footericon.css-13utstl {
        display: flex;
        justify-content: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    padding: auto;
    font-size: small;

}
.maindiv-homelog{
    display: flex;
    justify-content: left;
}

.footericon{
    padding: 0.6rem 1.8em ;
            margin-top: 2rem;
            margin-left: 1rem;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0%;

            border-radius: 50%;
            background-color: black;
            cursor: pointer;
}
.footer-text{
    text-align: center;
}
}

@media screen and (max-width: 1024px) {
    
    .footericon.css-13utstl {
        display: flex;
        justify-content: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    padding: auto;
    font-size: 1rem;

}
}

@media screen and (max-width: 1440px) {
    
    .footericon.css-13utstl {
        display: flex;
        justify-content: center;
    }
    .css-1jpvana-MuiTypography-root.footer-text
{
    display: flex;
    justify-content: center;
    padding: auto;
    font-size: 1rem;

}
}



@media screen and (max-width: 425px) {
    .header { position: relative !important; }
    .main-content { padding-top: 0 !important; }
}